import React from 'react'

interface Props {
  className?: string
  svg: React.ReactNode
  title?: React.ReactNode
  text: React.ReactNode
}

function Component({className = '', svg, text}: Props) {
  return (
    <div className={`p-4 xl:min-h-full min-h-fit ${className}`}>
      <div className="flex flex-col md:items-start items-center px-8 py-6 rounded-md bg-white md:shadow hover:shadow-xl shadow-xl h-full">
        {svg}
        {/*<h4 className="text-base font-semibold md:text-left uppercase text-gray-900 md:my-2 my-4">*/}
        {/*  {title}*/}
        {/*</h4>*/}
        <p className="pt-4 md:text-lg text-sm md:text-left text-gray-500 md:my-2 mb-8">
          {text}
        </p>
      </div>
    </div>
  )
}

export default Component
